@import '../../../styles/main.scss';

.bankShimmer{
    display: flex;
    flex-wrap: wrap;
}

.bankDiv {
  width: $universalSize-148;
  height: $universalSize-50; 
  border: $universalSize-1 solid $white-color-3;
  margin: 0 $universalSize-8 $universalSize-8 0;
  border-radius: $universalSize-8;

  // Shimmer animation
  background: linear-gradient(90deg, $shimmer-color-2 25%, $shimmer-color-1 50%, $shimmer-color-2 75%); 
  background-size: 200% 100%; 
  animation: shimmer $animation-duration infinite linear; 

   @include respond(medium) {
    width: 8rem;
   }
}
