@import '../../../styles/main.scss';

@mixin text {
  font-weight: 500;
  color: $text-color-4;
  margin: 0;
  font-size: normal;
}
@mixin box {
  width: 100%;
}
@mixin flex {
  @include box;
  display: flex;
}

.flex {
  @include flex;
  &.alignItemsCenter {
    align-items: center;
  }
  &.flexDirectionColumn {
    flex-direction: column;
  }
}

.rootContainer {
  width: fit-content;
  background: transparent;
  padding: $universalSize-15;
  box-sizing: border-box;
  margin-top: $universalSize-30;
  &.largeHeader {
    margin-top: $universalSize-60;
  }
  z-index: 1;
  visibility: hidden;
  &.showModal {
    visibility: inherit;
  }
  @include respond(large) {
    &.hideModal {
      &.largeHeader {
        display: none;
      }
    }
  }
  @media (min-width: $universalSize-1024) {
    margin-top: $universalSize-30;
  }
}
.modalContainer {
  width: 100%;
}

.modalWrapper {
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  @include respond(large) {
    left: 0;
  }
  &.largeHeader {
    @media (min-width: $universalSize-1170) {
      width: auto;
    }
    @media (min-width: $universalSize-1024) and (max-width: $universalSize-1170) {
      width: auto;
    }
  }
  &:not(.largeHeader) {
    @media (min-width: $universalSize-980) {
      width: auto;
    }
    @media (min-width: $universalSize-1024) and (max-width: $universalSize-980) {
      width: auto;
    }
  }
}
.modalBox {
  box-sizing: border-box;
  background: $white-color;
  border-radius: $universalSize-6;
  width: 100vw;

  @media (min-width: $universalSize-1024) {
    top: $universalSize-16;
    position: absolute;
    width: $universalSize-280;
    left: -$universalSize-312;
    margin-top: $universalSize-10;
    border: $universalSize-1 solid $light-blue-color-border;
    box-sizing: border-box;
    border-radius: $universalSize-6;
    padding: $universalSize-24;
    box-shadow: 0 $universalSize-2 $universalSize-8 $box-shadow-color-2;
  }
}

.name {
  @include text;
  font-weight: 600;
  font-size: $universalSize-18;
  line-height: $universalSize-24;
  color: $dark-purple-color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: $universalSize-6;
}

.email {
  @include text;
  font-size: $universalSize-12;
  line-height: $universalSize-16;
  color: $text-color-5;

  &:last-child {
    margin-bottom: 0;
  }
}
.profileLink {
  font-weight: 500;
  color: $blue-color;
  cursor: pointer;
}

.navTitle {
  @include text;
  color: $text-color;
  font-size: $universalSize-16;
  padding-left: $universalSize-16;
  @media (min-width: $universalSize-1024) {
    font-size: $universalSize-18;
    font-weight: 400;
    &:hover {
      font-weight: 500;
    }
  }
}
.logoutTitle {
  font-size: $universalSize-16;
  font-weight: 400;
  line-height: $universalSize-22;
  color: $text-color;
}

.profileSection {
  @include flex;
  align-items: center;
  cursor: default;
  justify-content: space-between;
  border-radius: $universalSize-8;
  box-sizing: border-box;
  margin-bottom: $universalSize-20;
  padding-left: $universalSize-16;
  @media (min-width: $universalSize-1024) {
    padding-left: unset;
  }
}

.basicInfo {
  @include flex;
  margin-left: $universalSize-8;
  justify-content: center;
  flex-direction: column;
  max-width: $universalSize-235;
}

.avatarContainer {
  @include flex;
  margin-right: $universalSize-5;
  width: fit-content;
}

.logoutWrapper {
  @include flex;
  padding: $universalSize-15 0 0 $universalSize-2;
  flex-direction: column;
}
.logoutRowWrapper {
  @include flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
}
.lastVisited {
  @include text;
  margin-top: $universalSize-12;
  font-size: $universalSize-10;
  line-height: $universalSize-12;
  color: $text-color-6;
}

.styledImageContainer {
  width: $universalSize-46;
  height: $universalSize-46;
}
.divider {
  @include box;
  opacity: 0.5;
  background: $light-red-color;
  border-bottom: $universalSize-1 dashed $white-color-border;
  @include respond(large) {
    &.hideOnMobile {
      display: none;
    }
  }
  &.mobileOnly {
    @media (min-width: $universalSize-1024) {
      display: none;
    }
  }
}

.navItemContainerWrapper {
  display: block;
  @include respond(large) {
    &.hideOnMobile {
      display: none;
    }
  }
}
.navItemContainer {
  @include flex;
  align-items: center;
  padding: $universalSize-25 $universalSize-5 $universalSize-25 $universalSize-16;

  line-height: $universalSize-24;
  padding-left: $universalSize-16;
  text-decoration: none;

  @media (min-width: $universalSize-1024) {
    padding-left: $universalSize-10;
    padding: $universalSize-8;
    margin-top: $universalSize-20;
    width: $universalSize-232;
  }
  color: $text-color-5;
  &:hover {
    background: $light-purple;
    cursor: pointer;
    border-radius: $universalSize-8;
    color: $dark-blue-color;
    font-weight: 500 !important;
  }
}

.icon {
  height: $universalSize-20;
  width: $universalSize-20;
}
