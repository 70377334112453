@import '../../styles/main.scss';

.belowTheFoldContainer {
  border: 1px solid rgba(255, 255, 255, 0);
  background: #f0ebf7;
  padding: $universalSize-48 $universalSize-96 $universalSize-2;

  @include respond(large) {
    padding: $universalSize-48 $universalSize-40 $universalSize-2;
  }

  @include respond(medium) {
    padding: $universalSize-32 $universalSize-20 $universalSize-2;
  }

  .footerRow0,
  .footerRow1,
  .footerRow2 {
    display: flex;
    background: #36303f;
    margin-bottom: $universalSize-20;
    border-radius: $universalSize-12;
    padding: $universalSize-16 $universalSize-24;
    cursor: pointer;

    @include respond(medium) {
      background: transparent;
      padding: 0;
      margin-bottom: 0rem;
    }

    .footerAckoContentbtfWrapper {
      display: flex;
      width: 100%;
      gap: $universalSize-10;

      @include respond(medium) {
        flex-direction: column;
      }

      .footerAckoTitleBtf {
        flex-basis: 25%;

        @include respond(medium) {
          background: #36303f;
          border-radius: $universalSize-12;
          padding: $universalSize-16;
          margin-bottom: $universalSize-16;
        }

        .footerAckoLinksHeading {
          display: flex;
          color: $white-color;
          font-size: $universalSize-14;
          font-weight: 600;
          line-height: $universalSize-20;
          letter-spacing: $universalSize-1;
          text-transform: uppercase;

          @include respond(medium) {
            justify-content: space-between;
            align-items: center;
          }

          span {
            visibility: hidden;
            @include respond(medium) {
              visibility: visible;
            }
          }
        }

        .footerAckoContentbtf {
          &.active {
            display: block;
          }

          &.inactive {
            display: none;
          }

          ul {
            list-style-type: none;
            padding: 0;
          }

          li {
            margin: 0 0 $universalSize-16 0;
            list-style-type: none;

            &:first-child {
              margin-top: $universalSize-24;
            }

            a {
              color: $white-color;
              font-size: $universalSize-14;
              font-weight: 400;
              line-height: $universalSize-20;

              &:hover {
                color: $white-color;
                font-weight: 600;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  &.isHomeWrapper {
    background: $red-color-800;
    .footerRow0,
    .footerRow1,
    .footerRow2 {
      .footerAckoContentbtfWrapper {
        .footerAckoTitleBtf {
          background: $purple-color-700;
        }
      }
    }
    &:not(.isMobile) {
      .footerRow0,
      .footerRow1,
      .footerRow2 {
        background: $purple-color-700;
      }
    }
  }
}

.footerTitleArrowUpMobile {
  display: none;

  @include respond(medium) {
    cursor: pointer;
    transform: scaleY(1);
    display: block;
  }
}

.footerTitleArrownClosedMobile {
  display: none;

  @include respond(medium) {
    cursor: pointer;
    transform: scaleY(-1);
    // margin-bottom: $universalSize-5;
    // height: fit-content;
    display: flex;
  }
}

.footerTitleArrowUp {
  cursor: pointer;
  transform: scaleY(1);
  display: block;

  @include respond(medium) {
    display: none;
  }
}

.footerTitleArrowClosed {
  cursor: pointer;
  transform: scaleY(-1);
  height: fit-content;
  display: block;

  @include respond(medium) {
    display: none;
  }
}

.cinSection{
  background: #f0ebf7;
  padding: $universalSize-8 $universalSize-96 $universalSize-48;
  @include respond(large) {
    padding: $universalSize-8 $universalSize-40 $universalSize-48;
  }

  @include respond(medium) {
    padding: $universalSize-8 $universalSize-20 $universalSize-48;
  }
  &.isHomeWrapper {
    background: $red-color-800;
  }
}
.innerContainer {
  &.cinSectionCenter{
  max-width: 74.0625rem;
  margin: 0 auto;
}
}