@import '../../styles/main.scss';

@mixin slideConnectUp($start, $end) {
  @keyframes slideConnectUp {
    from {
      bottom: $start;
    }
    to {
      bottom: $end;
    }
  }
}

.connectWithUsButtonWrapper {
  position: fixed;
  display: none;
  padding: $universalSize-8 $universalSize-12 $universalSize-8 $universalSize-8;
  align-items: center;
  gap: $universalSize-8;
  right: $universalSize-160;
  bottom: $universalSize-80;
  cursor: pointer;
  border: $universalSize-1 solid var(--line-primary-n-200, $white-color-3);
  background: var(--Neutral-N600, #040224);
  box-shadow: 0 $universalSize-4 $universalSize-6 -$universalSize-2 $blue-color-2;
  border-radius: $universalSize-46;
  z-index: 15;
  &:hover,
  &:focus {
    box-shadow: 0 $universalSize-4 $universalSize-4 0 rgba(0, 255, 121, 0.12);
  }
  @media only screen and (min-width: 760px) {
    &.goUp {
      @include slideConnectUp(0, $universalSize-112);
      animation: slideConnectUp 0.75s ease forwards;
      display: inline-flex;
    }
  }
}
.connectWithUsIconWrapper {
  width: $universalSize-32;
  padding: $universalSize-5 $universalSize-6 $universalSize-5 $universalSize-5;
}
.connectButtonText {
  color: $white-color;
}
.overlayModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15;
}
.modalWrapper {
  width: $universalSize-400;
  flex-shrink: 0;
  border-radius: $universalSize-24;
  padding-top: $universalSize-20;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background: $white-color;
  z-index: 20;
}
.modalContainer {
  width: 100%;
  padding: 0 $universalSize-20;
  display: flex;
  gap: $universalSize-16;
  flex-direction: column;
  &.success {
    padding: $universalSize-28 $universalSize-20 $universalSize-44 $universalSize-20;
  }
}
.heading {
  color: var(--text-primary-n-600, #040224);
  text-align: center;
  font-size: $universalSize-18;
  font-style: normal;
  font-weight: 700;
  line-height: $universalSize-22;
  width: $universalSize-270;
  place-self: center;
  &.success {
    width: $universalSize-320;
    margin-top: $universalSize-16;
  }
}
.phoneInputWrapper {
  width: 100%;
  border: none;
}
.crossContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 $universalSize-20;
}
.sideHeading {
  color: var(--Purple-P300, $purple-variant-3);
  text-align: center;
  font-size: $universalSize-12;
  font-style: normal;
  font-weight: 500;
  line-height: $universalSize-16;
  padding: $universalSize-5;
  background: var(--Purple-P50, $white-color-5);
  border-radius: $universalSize-24;
  width: $universalSize-210;
  align-self: center;
}

.crossIcon {
  cursor: pointer;
}
.supportIcon {
  justify-content: center;
  align-items: center;
  width: $universalSize-92;
  height: $universalSize-92;
  align-self: center;
  &.success {
    margin-top: $universalSize-16;
    width: $universalSize-90;
    height: $universalSize-90;
  }
}

.buttonWrapper {
  padding: $universalSize-24 $universalSize-20 $universalSize-20 $universalSize-20;
}
.inputWrapper {
  width: 100%;
  border-radius: $universalSize-8;
  padding: $universalSize-12;
  border: $universalSize-1 solid $white-color-4;
  &:focus {
    border: $universalSize-1 solid color(display-p3 0.8784 0.8784 0.9098);
  }
}

@include respond(small) {
  .connectWithUsButtonWrapper {
    right: $universalSize-32;
    &.goUp {
      @include slideConnectUp(0, $universalSize-112);
      animation: slideConnectUp 0.75s ease forwards;
      display: inline-flex;
    }
  }
  .modalWrapper {
    top: unset;
    width: 100%;
    bottom: 0;
    transform: translate(-50%, 100%);
    border-radius: $universalSize-24 $universalSize-24 0 0;
    transition: transform 0.5s ease-in-out;

    &.active {
      @include slideUpXY;
      animation: slideUp 0.5s forwards;
    }
  }

  .heading {
    font-weight: 700;
  }
  .sideHeading {
    font-size: $universalSize-14;
    width: $universalSize-240;
  }
}
@media only screen and (min-width: 480px) and (max-width: 760px) {
  .connectWithUsButtonWrapper {
    right: $universalSize-40;
    &.goUp {
      @include slideConnectUp(0, $universalSize-112);
      animation: slideConnectUp 0.75s ease forwards;
      display: inline-flex;
    }
  }
}

.button {
  border: $universalSize-1 solid #ccc;
  border-radius: $universalSize-8;
  padding: $universalSize-14;
  font-size: $universalSize-16;
  width: 100%;
  background-color: $button-background-color;
  &:hover,
  &:focus {
    background-color: $green-button-active-background-color;
    cursor: pointer;
  }
}
.buttonText {
  line-height: $universalSize-20;
  font-weight: $font-weight-card-heading;
  color: $white-color;
}
