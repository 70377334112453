@import '@styles/main.scss';

.BoxContainer {
  box-sizing: border-box;
  
  .TopicContainer {
    flex-direction: column;
    padding: $universalSize-20 $universalSize-20 0 $universalSize-20;
    box-sizing: border-box;
    border-radius: $universalSize-12;

    a {
      text-decoration: none;
      color: inherit;
    }

    @include respondMin(small) {
      border: $universalSize-1 solid $topic-border-color;
    }

    .TopicTitle {
      font-weight: $font-weight-section-heading;
      font-size: $universalSize-20;
      line-height: $universalSize-24;
      color: $topic-title-green-color;
      margin-bottom: $universalSize-26;
    }

    .AllTopics {
      display: flex;
      align-items: center;
      font-weight: $font-weight-small-para-heading;
      font-size: $universalSize-14;
      color: $black-color;
      background: $alltopics-background-color;
      border: $universalSize-1 solid $alltopics-border-grey-color;
      box-sizing: border-box;
      border-radius: $universalSize-9;
      padding: $universalSize-12;
      margin-bottom: $universalSize-20;

      img:last-child {
        margin-left: auto;
      }

      .IconWrap {
        margin-left: auto;
        width: unset;
      }
    }
  }
}
