@import '../../../styles/main.scss';
.button {
  cursor: pointer;
  width: 100%;
  align-items: center;
  padding: $universalSize-8 $universalSize-16;
  border-radius: $universalSize-8;
  font-weight: 600;
  line-height: $universalSize-32;
  font-size: $universalSize-16;

  &.primaryButton {
    background-color: #0fa457;
    border: none;
    color: $white-color;

    &:hover {
      background-color: #0b753e;
    }

    &:active {
      background-color: #064625;
    }
  }

  &.secondaryButton {
    border: 1px solid $dark-purple-color;
    color: $dark-purple-color;
    background-color: $white-color;

    &:hover {
      background-color: $heavy-light-purple;
    }

    &:active {
      background-color: $white-color-border;
    }
  }
}
