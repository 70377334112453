@import '../../styles/main.scss';

.googleRatingRootContainer {
  padding: $universalSize-96 $universalSize-24;

  @include respond(medium) {
    padding: $universalSize-40 $universalSize-24;
  }

  .googleRatingContainer {
    position: relative;
    margin: 0 auto;
    max-width: $universalSize-980;
    border: $universalSize-1 solid #f4f1f1;
    border-radius: $universalSize-24;
    padding: $universalSize-40;

    .backgroundImageGoogleRating {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1.5rem;
      z-index: -1;
    }

    @include respond(medium) {
      padding: $universalSize-20;
    }

    .mainWidgetSection {
      display: flex;
      flex-direction: row;
      gap: $universalSize-24;

      @include respond(medium) {
        flex-direction: column;
      }

      .imageContainer {
        flex-basis: 10%;
      }

      .googleMainRatingInfo {
        flex-basis: 65%;

        @media (min-width: 761px) and (max-width: 1000px) {
          flex-basis: 50%;
        }

        @media (min-width: 1001px) {
          flex-basis: 60%;
        }

        .mainHeading {
          @include desktopHeading3();
          margin-top: $universalSize-8;

          @include respond(medium) {
            @include mobileHeading3();
            margin-top: $universalSize-8;
          }
        }

        .mainPara {
          @include commonParagraph(null, null, $text-color);
          color: #5c5677;
          margin-top: $universalSize-8;
        }

        .seeReviews {
          @include commonParagraph(null, null, $text-color);
          margin-top: $universalSize-16;
          background: linear-gradient(91deg, #6833e2 -44.74%, #e60fbd 74.96%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        //Preview Google Rating icon
        .googleRatingStars {
          display: flex;
          align-items: center;

          .ratingPreviewStyle {
            display: flex;
          }

          .ratingPreviewItem {
          }

          .ratingGrade {
            font-size: $universalSize-24;
            font-style: normal;
            font-weight: 700;
            line-height: $universalSize-34;
            margin-left: $universalSize-12;
          }
        }
      }
    }
  }
}
