@import '../../styles/main.scss';

.rootContainer {
  width: 100vw;
  position: relative;
  @include respond(medium) {
    @include flex($flex-flow: column nowrap);
  }
}
.backgroundImageContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: $zindex-background-absolute;
}
.backgroundImageMobile {
  display: none;
  @include respond(small) {
    display: block;
  }
}
.backgroundImageTab {
  display: none;
  @include respondRange(480px, 1024px) {
    display: block;
  }
}

.backgroundImageDesktop {
  display: none;
  @media only screen and (min-width: 1024px) {
    display: block;
  }
}

.bannerWrapper {
  width: 100%;
  padding: $universalSize-140 $universalSize-120;
  @include flex($justify-content: end, $align-items: center);

  @include respondRange(760px, 1024px) {
    padding: $universalSize-32 $universalSize-48;
  }
  @include respond(medium) {
    padding: $universalSize-24 $universalSize-20;
  }
  @include respond(large) {
    min-height: $universalSize-700;
    flex-direction: column;
  }
}

.contentWrapper {
  @include flex($flex-flow: column nowrap, $justify-content: center, $align-items: flex-start);
  gap: $universalSize-48;
  width: 50%;
  padding: $universalSize-24;

  @include respondRange(760px, 1024px) {
    padding: 0;
    width: 70%;
  }
  @include respond(medium) {
    width: 100%;
  }
}

.headingWrapper {
  @include flex($flex-flow: column nowrap, $justify-content: center, $align-items: flex-start);
  gap: $universalSize-16;
  align-self: stretch;
}

.heading {
  color: #121212;
  font-size: $universalSize-44;
  font-weight: 600;
  line-height: $universalSize-56;
  @include respond(medium) {
    font-size: $universalSize-36;
    line-height: $universalSize-48;
  }
  @include respond(large) {
    text-align: center;
  }
}

.subHeading {
  color: #4b4b4b;
  font-size: $universalSize-18;
  font-weight: $font-weight-para-heading;
  line-height: $universalSize-28;
  @include respond(medium) {
    font-size: $universalSize-16;
    line-height: $universalSize-24;
  }
  @include respond(large) {
    align-self: center;
    text-align: center;
  }
}
.inputContainer {
  @include flex($justify-content: center, $align-items: center);
  gap: $universalSize-12;
  outline: none;
  border: none;
  &:focus {
    border: $universalSize-1 solid $purple-variant-2;
  }
  @include respond(medium) {
    flex-direction: column;
    gap: $universalSize-24;
    width: 100%;
  }
  @include respond(large) {
    align-self: center;
  }
}
.inputWrapper {
  height: 100%;
  @include respond(medium) {
    width: 100%;
  }
  @include respondRange(760px, 1024px) {
    width: 100%;
  }
}
