@import '../../styles/main.scss';
.cardCta {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  @include respond(medium) {
    width: 100%;
  }

  &.CardCtaWithRightArrow {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      background: linear-gradient(91deg, #6833e2 -44.74%, #e60fbd 74.96%);
      -webkit-background-clip: text;
      color: transparent;
      font-size: $universalSize-18;
      font-style: normal;
      font-weight: 600;
      line-height: $universalSize-28;
      margin-right: $universalSize-12;
    }

    img {
      width: $universalSize-24 !important;
      height: $universalSize-24 !important;
      object-fit: cover;
    }
  }

  &.GreenSubmitCta {
    min-width: 117px;
    span {
      @include desktopPara3();
      text-align: center;
      color: $white-color;
      background: $button-background-color;
      border-radius: $universalSize-8;
      padding: $universalSize-16;
      font-weight: 600;

      /* Apply full width on mobile */
      @include respond(medium) {
        display: block;
        width: 100%;
      }
    }

    img {
      display: none;
    }
  }
}
