@mixin flex($flex-flow: row nowrap, $justify-content: flex-start, $align-items: stretch) {
  display: flex;
  flex-flow: $flex-flow;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin flex-center {
  @include flex($justify-content: center, $align-items: center);
}

@mixin v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin h-center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@mixin hv-center {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
