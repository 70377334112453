@import '@styles/main.scss';


.TopicsContainer {
  display: flex;
  align-items: center;
  border: $universalSize-1 solid $alltopics-border-grey-color;
  box-sizing: border-box;
  border-radius: $universalSize-9;
  padding: $universalSize-15 $universalSize-12;
  margin-bottom: $universalSize-20;
  font-size: $universalSize-14;
  font-weight: $font-weight-small-para-heading;
  cursor: pointer;

  .TopicIconWrapper {
    width: unset;
    margin-right: $universalSize-18;
  }

  .TopicName {
    hyphens: auto;
  }

  .IconWrap {
    margin-left: auto;
    width: unset;
  }
}
