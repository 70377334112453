@import '@styles/main.scss';

.TagContainer {
  padding: $universalSize-20 0;
  box-sizing: border-box;
  background: $white-color;
  display: flex;
  flex-wrap: wrap;

  @include respondMin(large) {
    padding: 0;
  }

  .TagItem {
    font-size: $font-size-12;
    font-weight: $font-weight-para-heading;
    color: $text-color-5;
    background: $background-articles-silver-color;
    border-radius: $universalSize-4;
    width: auto;
    margin-right: $universalSize-5;
    margin-left: $universalSize-5;
    padding: $universalSize-5;
    margin-bottom: $universalSize-5;

    @include respondMin(small) {
      font-size: $font-size-xsmall;
    }

    .TagText {
      margin-left: $universalSize-5;
      color: $dark-blue-color;
    }
  }
}
