@import '../../../styles/main.scss';

.mainContainer {
  width: 100%;
}

.mainContainerModalOpen {
  min-width: $universalSize-560;
  padding: $universalSize-40 $universalSize-24;
  color: $black-color;
  @include respond(medium) {
    text-align: start;
    min-width: 0;
  }
}

.widgetForm {
  display: flex;
  flex-direction: column;
  padding-top: $universalSize-16;
  width: 80%;

  @include respond(medium) {
    width: 100%;
  }
  input {
    height: $universalSize-48;
    width: 100%;
    text-transform: uppercase;
    color: $purple-variant-2;
    border: 1px solid $white-color-border;
    border-radius: $universalSize-12;
    outline: none;
    font-size: $font-size-xsmall;
    line-height: $line-height-xsmall;
    padding-left: $universalSize-60;
  }

  button {
    display: flex;
    margin-top: $universalSize-16;
    justify-content: center;
    align-items: center;
    font-family: inherit;
  }
}

.widgetFormModal {
  width: 100%;
}

.widgetHeading {
  font-size: $font-size-small;
  font-weight: $font-weight-card-heading;
  margin-bottom: $universalSize-16;
  text-align: start;
}

.InputBoxContainer {
  position: relative;

  .inputIcon {
    position: absolute;
    width: auto;
    height: 100%;
  }
}

.inputError {
  padding: $universalSize-10 $universalSize-15;
  background: $blush_pink;
  border-radius: $universalSize-8;
  box-sizing: border-box;
  margin-top: $universalSize-6;
  @include fadeIn;
  position: relative;
  color: $widget-error-text-color;
  text-align: start;
}

.fastagModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-color-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z-index-30;
}

.fastagModalLogin {
  display: none;
}

.toggelSvg {
  transform: rotate(180deg);
}

.loginText {
  display: flex;
  width: 80%;
  position: absolute;
  top: -8%;
  color: $purple-color-700;
  font-size: $font-size-xsmall;
  font-weight: $font-weight-para-heading;
  letter-spacing: $universalSize-2;
}

.loginContainer {
  position: relative;
  text-align: start;
}

@include respond(medium) {
  .fastagModal {
    align-items: end;
  }

  .inputIcon {
    width: 12%;
  }

  .loginText {
    top: -31%;
  }
}
