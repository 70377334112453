@import '../../styles/main.scss';

.globalFooterRootContainer {
  position: relative;
  z-index: $zindex-grid-root-container;

  .globalFooterMainAtfContainer {
    position: relative;
    padding-top: $universalSize-96;
    z-index: $zindex-grid-root-container;
    background: #f5f3fa;
    padding-bottom: $universalSize-40;

    .colorBlendParent {
      @include colorBlendCircles(rgb(173 109 244/1), rgb(255 95 228/1), 320px, 320px, null, true);

      @include respond(medium) {
        @include colorBlendCircles(rgb(173 109 244/1), rgb(255 95 228/1), 214px, 214px, null, true);
      }
    }

    .ackoMainInfo {
      margin: 0 auto;
      max-width: $universalSize-980;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @media (max-width: 1120px) {
        padding: 0 $universalSize-24;
      }

      @include respond(medium) {
        flex-direction: column;
      }

      .ackoInfoBlock {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;

        p {
          @include commonParagraph(null, null, $text-color);
          margin-top: $universalSize-24;
          font-weight: 600;

          @include respond(medium) {
            font-weight: 600;
          }

          &:last-child {
            font-size: $universalSize-14;
            font-style: normal;
            font-weight: 400;
            line-height: $universalSize-20;
            margin-top: $universalSize-5;
          }
        }
      }

      .socialMediaIcons {
        display: flex;
        gap: $universalSize-12;
        flex-grow: 1;
        justify-content: flex-end;

        @include respond(medium) {
          width: 100%;
          justify-content: flex-start;
          margin-top: $universalSize-16;
        }
      }
    }

    .ackoGroupCompanies {
      margin: 0 auto;
      max-width: $universalSize-980;
      display: flex;
      flex-direction: column;
      margin-top: 0.875rem;

      @media (max-width: 1120px) {
        padding: 0 $universalSize-24;
      }

      p {
        color: $text-color;
        margin: 0;
        font-size: 1.5rem;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.375rem;
      }

      ul {
        list-style-type: none;
        // margin: 0 0 1.75rem 0;
        padding: 0;
        display: block;
      }

      li {
        margin: 0 0 0.75rem 0;

        &:first-child {
          margin-top: 0.625rem;
        }
      }

      a {
        color: #151619;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-decoration: none;

        &:hover {
          color: #5b5675;
          cursor: pointer;
        }
      }
    }

    .semBottomSection {
      margin: 0 auto;
      max-width: $universalSize-980;
      padding-top: $universalSize-32;

      @media (max-width: 768px) {
        padding: $universalSize-24;
      }

      .legalInfo {
        display: flex;
        gap: 24px;

        @media (max-width: 768px) {
          flex-direction: column;
          gap: 4px;
        }

        p {
          line-height: 1rem;
          font-size: 0.75rem;
          font-weight: 500;
        }
      }

      .semBottomText {
        padding-top: $universalSize-16;

        p {
          line-height: 1.125rem;
          font-size: 0.75rem;
          font-weight: 400;
          color: #5b5675;
          padding-bottom: $universalSize-16;
        }
      }
    }
  }

  .ackoFooterdividerWrapper {
    width: 100%;
    height: $universalSize-1;

    .ackoFooterdivider {
      box-sizing: border-box;
      max-width: $universalSize-980;
      margin: 0 auto;
      height: 100%;
      border: 1px solid #fff;
    }
  }

  .ackoMainContentLinksContainer {
    background: #f5f3fa;
    position: relative;
    z-index: -10;
    padding-top: $universalSize-40;

    .colorBlendParent {
      @include colorBlendCircles(rgb(173 109 244/1), rgb(255 95 228/1), 520px, 380px, null, true);

      @include respond(medium) {
        @include colorBlendCircles(rgb(173 109 244/1), rgb(255 95 228/1), 214px, 214px, null, true);
      }
    }

    .ackoMainContentLinks {
      display: flex;
      flex-wrap: wrap;
      padding: $universalSize-16 0;
      margin: 0 auto;
      max-width: $universalSize-980;

      @media (max-width: 1120px) {
        padding: $universalSize-16 $universalSize-24;
      }

      @include respond(medium) {
        flex-direction: column;
        gap: $universalSize-24;
      }

      .ackoContentLinkItem {
        flex-basis: 25%;

        // Title Item
        .footerItemTitle {
          display: flex;
          color: $text-color;
          font-size: $universalSize-14;
          font-weight: 600;
          line-height: $universalSize-20;
          letter-spacing: $universalSize-1;
          text-transform: uppercase;

          @include respond(medium) {
            justify-content: space-between;
          }

          .footerTitleArrowUp {
            display: none;
            cursor: pointer;

            @include respond(medium) {
              transform: scaleY(1);
              display: block;
            }
          }

          .footerTitleArrowClosed {
            display: none;
            cursor: pointer;

            @include respond(medium) {
              height: 24px;
              transform: scaleY(-1);
              display: block;
            }
          }
        }

        .ackoMainContentLinksList {
          &.active {
            @include respond(medium) {
              display: block;
            }
          }

          &.inactive {
            @include respond(medium) {
              display: none;
            }
          }

          ul {
            list-style-type: none;
            padding: 0;
          }

          li {
            margin: 0 0 $universalSize-16 0;
            list-style-type: none;

            &:first-child {
              margin-top: $universalSize-24;
            }

            a {
              color: $text-color;
              font-size: $universalSize-14;
              font-weight: 400;
              line-height: $universalSize-20;

              &:hover {
                color: #5b5675;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .bottomLabel {
    line-height: 1.4375rem;
    color: #000;
    margin: 0 2rem 0 0;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .bottomLabelSecondary {
    color: #5c5677;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
  }

  .wordingLineBreak {
    margin-bottom: $universalSize-16;
  }

  .policywordingFooterAlignment {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 2.625rem;
    font-style: normal;
  }

  &.isHomeWrapper {
    .globalFooterMainAtfContainer {
      background: $purple-color-700;

      .colorBlendParent {
        display: none;
      }

      .ackoMainInfo {
        .ackoInfoBlock {
          p {
            color: $white-color;

            &:last-child {
              color: #a6a6a6;
            }
          }
        }
      }

      .ackoGroupCompanies {
        display: none;
      }
    }

    .ackoFooterdividerWrapper {
      background: $purple-color-700;

      .ackoFooterdivider {
        border: $universalSize-1 solid rgba(231, 231, 240, 0.4);
      }
    }

    .ackoMainContentLinksContainer {
      background: $purple-color-700;

      .colorBlendParent {
        display: none;
      }

      .ackoMainContentLinks {
        .ackoContentLinkItem {
          .footerItemTitle {
            color: $white-color;
          }

          .ackoMainContentLinksList {
            a {
              color: $white-color;
            }
          }
        }
      }
    }

    .bottomLabel {
      color: $white-color;
    }

    .bottomLabelSecondary {
      color: #a6a6a6;
    }
  }
}