@import '../../styles/main.scss';

.CarouselContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: $universalSize-380; /* Fixed width for all screens */
  overflow: hidden;
  overscroll-behavior-inline: contain;
  scroll-snap-type: x mandatory; /* Enable scroll snapping */
  margin-bottom: $universalSize-20;
  place-items: center;
  // border-bottom: 1px solid #ffffff;
  /* Set up grid layout for larger screens */
  @media (min-width: 992px) {
    /* Adjust grid layout */
    grid-template-columns: repeat(auto-fill, minmax($universalSize-380, 1fr)); /* Adjust the width of each card */
    gap: $universalSize-24; /* Add gap between cards */

    /* Adjust margin to remove gap from outer edges */
    margin-left: calc(($universalSize-24) * -1); /* Negative margin to counteract gap */
    margin-right: calc(($universalSize-24) * -1); /* Negative margin to counteract gap */
  }

  @media (min-width: 481px) and (max-width: 992px) {
    gap: $universalSize-24;
  }

  /* Add overflow-x: auto for small screens */
  @include respond(small) {
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1.25rem;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-snap-type: mandatory;

    gap: $universalSize-24; /* Add gap between cards */

    /* Hide scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .card {
    &.carouselCard {
      position: relative;

      padding: $universalSize-40;
      border-radius: $universalSize-32;
      background: $plain-white-color;
      border: $universalSize-1 solid #e8e8e8;
      max-width: $universalSize-380;
      width: 100%;
      height: auto;

      @include respond(medium) {
        padding: $universalSize-32;
      }
      .circle-pattern {
        position: absolute;
        top: 0rem;
        right: 0rem;
        z-index: 1;

        img {
          border-top-right-radius: $universalSize-32;
        }
      }

      h3,
      h4,
      h5,
      h6 {
        @include desktopHeading4();
        margin-top: $universalSize-20;

        @include respond(medium) {
          @include mobileHeading4();
        }
      }

      p {
        @include commonParagraph(null, null, $text-color);

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        &:not(:has(img)) {
          margin: $universalSize-20 0rem 0rem 0rem;
        }
      }
    }

    &.carouselCard > span > p > img {
      width: $universalSize-32;
      height: $universalSize-32;
      object-fit: cover;
    }
  }
}

.CarouselArrows {
  @include flex-center();
  gap: $universalSize-40;
  margin: 0 auto;

  button {
    all: unset;
    cursor: pointer;
  }
}
