@import '../../styles/main.scss';

.joinMillionRootContainer {
  padding: $universalSize-96 $universalSize-24 $universalSize-12 $universalSize-24;

  background: linear-gradient(60deg, color(display-p3 1 1 1) 10%, color(display-p3 1 1 1 / 0) 22%),
    linear-gradient(0deg, color(display-p3 1 1 1) 0%, color(display-p3 1 1 1 / 0) 91%),
    radial-gradient(
      57.93% 52.99% at 56.11% 47.99%,
      color(display-p3 1 1 1 / 0) 0%,
      color(display-p3 0.4392 0.8118 1 / 0.19) 50%,
      color(display-p3 1 1 1 / 0) 100%
    ),
    linear-gradient(
      236deg,
      color(display-p3 1 1 1 / 0) 0.9%,
      color(display-p3 0.3804 0.298 1 / 0.3) 24.99%,
      color(display-p3 1 1 1 / 0) 69.33%
    ),
    linear-gradient(235deg, color(display-p3 1 1 1 / 0) 44.89%, color(display-p3 0.4863 0.0784 0.9882 / 0.23) 62.23%);
  @include respond(medium) {
    padding: $universalSize-40 $universalSize-24;
  }

  .sectionHeading {
    text-align: center;
    margin: 0 auto;
    max-width: $universalSize-980;

    h2 {
      @include desktopHeading2();

      @include respond(medium) {
        @include mobileHeading2();
      }
    }

    h3 {
      @include desktopHeading3();

      @include respond(medium) {
        @include mobileHeading3();
      }
    }

    p {
      @include commonParagraph($universalSize-24, $universalSize-16, $text-color-2);
    }
  }

  .gridSectionForData {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: $universalSize-64 0rem;
    max-width: $universalSize-980;

    @include respond(medium) {
      padding: $universalSize-32 0rem;
    }

    .joinMillionDataContainer {
      border-right: 1px solid #fff;
      padding: $universalSize-16;
      flex-basis: 25%;
      text-align: center;

      &:last-child {
        border-right: none;
      }

      @include respond(medium) {
        flex-basis: 50%;
        padding: $universalSize-20 $universalSize-16;
        &:nth-child(2),
        &:nth-child(4) {
          border-right: none;
        }

        &:nth-child(3),
        &:nth-child(4) {
          border-top: 1px solid #fff;
        }
      }

      h3,
      h4,
      h5,
      h6 {
        @include desktopHeading3();
        color: #611bcd;
        @include respond(medium) {
          @include mobileHeading3();
          color: #611bcd;
        }
      }

      p {
        color: #040224;
        font-size: $universalSize-16;
        font-style: normal;
        font-weight: 300;
        line-height: 2.4ren;
        padding-top: 12px;
      }
    }
  }

  .sectionCta {
    margin: 0 auto;
    max-width: $universalSize-980;
    text-align: center;
  }
}
