@import '../../styles/main.scss';

.header {
  height: $universalSize-64;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $white-color;
  position: relative;
  z-index: 9;

  @include respond(large) {
    overflow-x: hidden;
  }

  .headerMainWrapper {
    &.isLoggedIn {
      height: $universalSize-64;
    }
  }

  .backButtonWhite,
  .backButton {
    font-style: normal;
    font-weight: 500;
    font-size: $universalSize-16;
    line-height: $universalSize-22;
    margin-left: $universalSize-5;
    color: $blue-color;
    text-decoration: none;
  }

  .backButtonWhite {
    color: $white-color;
  }

  .hideOnMobile {
    @include respond(large) {
      display: none !important;
    }
  }

  .hideOnDesktop {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageWrapper {
    display: none;

    img {
      width: $universalSize-370;
      height: $universalSize-400;
      border-radius: $universalSize-40;
    }
  }

  &.isHeaderVisible {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    padding-bottom: $universalSize-100;
    z-index: 9999;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    } /* safari and chrome */

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &.showingLinks {
      .buttonSection {
        display: none;
      }
    }
  }

  & .categoriesWrapper {
    margin-top: $universalSize-100;
    transition: margin 300ms;
    display: none;
  }
  .isHeaderVisible {
    &.categoriesWrapper {
      margin-top: $universalSize-64;
      display: block;

      @include respond(large) {
        margin-bottom: $universalSize-100;
      }
    }
  }

  .addBlock {
    &.isHeaderVisible {
      &.categoriesWrapper {
        margin-top: $universalSize-140;
      }
    }

    &.largeHeader {
      margin-top: $universalSize-64;
    }
  }

  .iconWrapper {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    transition: left 500ms;
    padding: $universalSize-18;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    border-bottom: $universalSize-1 solid $white-color-border;
    background: $white-color;

    &.isHeaderVisible {
      position: fixed;
    }

    @media only screen and (max-width: $universalSize-1024) {
      &.darkMobile {
        background: $purple-header-color;
        border-bottom: unset;
      }
      &.dark {
        background: linear-gradient(to right, #11091d, #0e0715, #030304);
        border: none;
      }
    }

    &.largeHeader {
      height: $universalSize-64;
    }

    &:not(.largeHeader) {
      height: $universalSize-32;
    }
  }

  .categories {
    cursor: pointer;

    &.currentlyActive {
      color: $black-color;
    }
  }
  .categorySubWrapper {
    display: flex;
    justify-content: space-between;
    padding: $universalSize-25 $universalSize-22;
    border-bottom: $universalSize-1 dashed $white-color-border;
    align-items: center;

    &.currentlyActive {
      color: $purple-color;
    }
  }

  .categoryInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      svg,
      img {
        width: $universalSize-28;
        height: $universalSize-28;
      }
    }

    .categoryTitle {
      font-size: $universalSize-16;
      font-weight: 500;
      line-height: $universalSize-22;
      text-align: left;
      margin: 0 $universalSize-16;
    }
  }
  .buttonSection {
    box-shadow: 0 -$universalSize-3 $universalSize-6 -$universalSize-2 $box-shadow-color-1;
    padding: $universalSize-12 0 $universalSize-20;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: -$universalSize-80;
    left: 0;
    right: 0;
    background: $white-color;
    transition: left 500ms;
    gap: $universalSize-20;
  }
  &.isHeaderVisible {
    .buttonSection {
      bottom: 0;
      transition: bottom 800ms;
    }
  }
  .buttonWrapper {
    width: $universalSize-152;
    height: $universalSize-48;
    &.forDesktop {
      @media only screen and (max-width: $universalSize-1024) {
        display: none;
      }
    }
    &.forMobile {
      height: $universalSize-32;
      width: $universalSize-96;
      @media only screen and (min-width: $universalSize-1024) {
        display: none;
      }
    }
  }

  .sections {
    padding: $universalSize-24 $universalSize-20$universalSize-12 $universalSize-60;
    z-index: 9;
    background: $heavy-light-purple;
  }

  .accordionWrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms;
    background: $white-color;
    &.accordionVisible {
      max-height: $universalSize-1000;
    }
  }

  .sectionTitle {
    font-size: $universalSize-12;
    font-weight: 500;
    line-height: $universalSize-18;
    text-align: left;
    padding-bottom: $universalSize-12;
  }

  .typeTitle {
    padding: $universalSize-12 0;
    font-size: $universalSize-16;
    font-weight: 400;
    line-height: $universalSize-24;
    letter-spacing: 0;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;
    }
  }

  .headerButton {
    width: $universalSize-24;
    height: $universalSize-24;
    position: relative;

    &.buttonCross {
      .buttonVisuals {
        width: 0;

        &::before {
          transform: translateY($universalSize-10) rotateZ(-45deg);
        }

        &::after {
          transform: translateY(-$universalSize-14) rotateZ(45deg);
        }
      }
    }
    &.dark {
      &.buttonCross {
        .buttonVisuals {
          &::before {
            top: -$universalSize-12;
            transform: translateY($universalSize-7) rotateZ(-45deg);
          }

          &::after {
            bottom: $universalSize-12;
            transform: translateY(-$universalSize-12) rotateZ(45deg);
          }
        }
      }
    }
  }
  .headerButtonInput {
    width: $universalSize-40;
    height: $universalSize-40;
    transform: translate(-$universalSize-10, -$universalSize-10);
    opacity: 0;
    position: relative;
  }
  .buttonVisuals {
    width: $universalSize-18;
    height: $universalSize-2;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: width 100ms;
    background: $purple-color-4;
    &.darkMobile {
      background: $white-color;
    }
    &::before,
    &::after {
      content: ' ';
      position: absolute;
      height: $universalSize-2;
      top: -$universalSize-12;
      right: 0;
      width: $universalSize-24;
      transition: transform 100ms;
      background: $purple-color-4;
    }
    &::after {
      top: $universalSize-12;
    }

    &.darkMobile::before,
    &.darkMobile::after {
      background: $white-color;
    }
    &.dark {
      width: 0;
      height: 0;
      &::before,
      &::after {
        top: -$universalSize-7;
      }
      &::after {
        top: $universalSize-7;
      }
    }
  }

  .toggleIcon {
    width: $universalSize-24;
    height: $universalSize-24;
    position: relative;

    &::before,
    &::after {
      content: ' ';
      position: absolute;
      width: $universalSize-24;
      height: $universalSize-2;
      background: $purple-color-4;
      top: $universalSize-12;
      transition: transform 500ms;

      &::before {
        transform: rotateZ(90deg);
      }
    }

    &.isActive::before {
      transform: rotateZ(0deg);
    }
  }

  .links {
    position: fixed;
    top: $universalSize-60;
    bottom: 0;
    left: 100vw;
    background: $white-color;
    padding-bottom: $universalSize-173;
    padding-top: $universalSize-12;
    z-index: 9;
    height: 100vh;

    &.largeHeader {
      width: 100vw;
    }

    &:not(.largeHeader) {
      width: 93vw;
    }

    &.showLink {
      left: 0vw;
      padding: 0 $universalSize-16;

      @media only screen and (max-width: $universalSize-764) {
        padding: $universalSize-8 $universalSize-16;
      }

      .noLink {
        margin-left: $universalSize-8;
      }

      .bottomImage {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .linkWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        width: $universalSize-20;
        height: $universalSize-20;
      }
    }
  }
  .regularLink {
    display: block;
    padding: $universalSize-12 $universalSize-10;
    font-size: $universalSize-16;
    font-weight: 400;
    line-height: $universalSize-24;
    text-align: left;
    color: $dark-purple-color;
    text-decoration: none;

    &:hover {
      background: $heavy-light-purple;
      border-radius: $universalSize-8;
      color: $purple-color;
      font-weight: 500;
    }
  }
  .noLink {
    display: block;
    padding: $universalSize-8 0;
    margin: 0 $universalSize-20;
    font-size: $universalSize-12;
    font-weight: 500;
    line-height: $universalSize-16;
    letter-spacing: $universalSize-1;
    text-align: left;
    color: $purple-variant-2;
    text-decoration: none;
    cursor: default;
  }
  .blue {
    color: $blue-color;
  }
  .categoryDetails {
    display: none;
  }

  .link {
    padding: $universalSize-12 0;
  }

  .linkDividers {
    padding: $universalSize-12 0;
    border-bottom: $universalSize-1 solid $heavy-light-purple;
    display: flex;
    justify-content: space-between;
  }

  .borderedLink {
    font-size: $universalSize-16;
    font-weight: 500;
    line-height: $universalSize-24;
    text-align: left;
    color: $purple-color;
    border: $universalSize-1 solid $purple-color-border;
    border-radius: $universalSize-8;
    padding: $universalSize-10 $universalSize-20;
    text-decoration: none;
    margin: $universalSize-12 0;
  }

  .accordionVisible {
    &.accordionWrapper {
      .sectionTitle {
        font-size: $universalSize-12;
        font-weight: 500;
        line-height: $universalSize-16;
        letter-spacing: $universalSize-1;
        text-align: left;
        color: $purple-variant-2;
      }
    }
  }

  .headerMainWrapper {
    &.isHeaderVisible {
      padding-bottom: $universalSize-150;
    }
  }
  .faqSection {
  }

  .faqWrapper {
    cursor: pointer;
    width: 100%;
    padding: $universalSize-8 $universalSize-16;
    border-radius: $universalSize-8;
    font-weight: 600;
    line-height: $universalSize-32;
    font-size: $universalSize-14;
    border: 1px solid $text-color;
    background: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $universalSize-10;
    &.isSem {
      padding: $universalSize-5 $universalSize-16;
    }
    &:hover {
      background-color: $heavy-light-purple;
    }

    &:active {
      background-color: $white-color-border;
    }

    &.forMobile {
      padding: $universalSize-10 $universalSize-8;
      gap: $universalSize-6;
      height: 100%;
    }
  }
  .faqText {
    font-weight: 600;
    line-height: 2rem;
  }

  @media only screen and (min-width: $universalSize-1024) {
    .links.showLink .bottomImage {
      display: none;
    }
    .typeTitle {
      font-size: $universalSize-18;
      line-height: $universalSize-24;
    }
    .hideOnMobile {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .hideOnDesktop {
      display: none !important;
    }
    height: unset;
    z-index: 9;
    &.isHeaderVisible {
      position: absolute;
    }

    & {
      flex-direction: row;
      width: 100%;
      justify-content: center;
    }
    .headerButton {
      display: none;
    }
    .iconWrapper {
      position: relative;
      top: unset;
      left: unset;
      right: unset;
      background: $white-color; // Default value
      transition: left 500ms;
      margin-right: $universalSize-20;
      &.darkDesktop {
        background: $purple-header-color;
      }
      &.dark {
        background: linear-gradient(to right, #11091d, #0e0715, #030304);
        border: none;
      }
    }
    .iconWrapper,
    .categories {
      padding: unset;
      display: flex;
      justify-content: space-between;
      border-bottom: unset;
      align-items: center;
      flex-shrink: 0;
      .categoryDetails,
      .accordionWrapper,
      .imageWrapper {
        margin-left: 0;
      }
      &.currentlyActive .categoryDetails,
      &.currentlyActive .accordionWrapper,
      &.currentlyActive .imageWrapper {
        margin-top: 0;
      }
    }

    .buttonSection {
      box-shadow: unset;
      padding: unset;
      justify-content: unset;
      bottom: unset;
      position: relative;
      top: 0;
      margin-left: auto;
      background: unset;
      transition: unset;
      display: flex;
      align-items: center;
      height: $universalSize-40;
      &.hasFaq {
        margin-right: $universalSize-20;
      }
    }

    .headerMainWrapper {
      display: flex;
      align-items: center;
      height: $universalSize-41;
      width: 86vw;
      border-bottom: $universalSize-1 solid $white-color-border;
      background: $white-color;
      padding: $universalSize-20 calc((100vw - $universalSize-980) / 2);
      &.largeHeader {
        width: 100vw;
        padding: $universalSize-40 calc((100vw - $universalSize-980) / 2);
      }
      &.darkDesktop {
        background: $purple-header-color;
        border-bottom: none;
      }
      &.dark {
        background: linear-gradient(to right, #11091d, #0e0715, #030304);
        border-bottom: none;
      }
      &.isHomeWrapper {
        padding: $universalSize-40 $universalSize-120;
      }
      &.onlyFaqSection {
        justify-content: space-between;
      }
    }

    & .categoriesWrapper {
      margin-top: unset;
      transition: unset;
      display: flex;
      justify-content: flex-start;
    }
    .categoryInfo span svg {
      display: none;
    }
    .accordionWrapper {
      display: none;
    }
    .accordionVisible {
      &.accordionWrapper {
        display: block;
        position: absolute;
        top: $universalSize-82;
        left: 21vw;
        width: 43vw;
        overflow: visible;
        height: calc(100% - $universalSize-80);
        .sections {
          padding: $universalSize-26 $universalSize-32 0;
          width: fit-content;
          min-width: $universalSize-210; // Default value
          &.largeHeader {
            min-width: $universalSize-270;
          }
          .links {
            left: 19vw;
            top: $universalSize-10;
            width: 24vw;
            background-color: $white-color;
            transition: unset;
            margin-top: $universalSize-56;
          }
        }
        .sectionTitle {
          font-size: $universalSize-12;
          font-weight: 500;
          line-height: $universalSize-16;
          letter-spacing: $universalSize-1;
          text-align: left;
          color: $purple-variant-2;
        }

        .typeTitle {
          padding: $universalSize-10 0.7320644216691069vw;
          margin-left: -0.7320644216691069vw;
          cursor: pointer;
        }
        .typeTitle {
          &.active {
            background: $heavy-light-purple;
            border-radius: $universalSize-8;
            color: $purple-color;
            font-weight: 500;
          }
        }
      }
    }

    .sectionTitle {
      padding-top: $universalSize-14;
    }

    .categoryInfo {
      .categoryTitle {
        font-size: $universalSize-16;
        font-weight: 400;
        line-height: $universalSize-22;
        letter-spacing: 0;
        text-align: left;
        margin: 0 $universalSize-8;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }
    }
    &.activeSection {
      height: $universalSize-615;
      position: absolute;
      top: 0;
      box-shadow: 0 $universalSize-2 $universalSize-8 $box-shadow-color-2;
    }
    .links {
      position: absolute;
      top: unset;
      bottom: unset;
      left: unset;
      width: unset;
      background: $white-color-2;
      padding-bottom: unset;
      height: $universalSize-432;
      max-width: 24vw;
      padding-top: unset;
      display: none;
      &.showLink {
        height: $universalSize-450;
        display: block;
      }
      .regularLink {
        margin-left: 0;
      }
    }

    .regularLink {
      &::hover {
        background: $heavy-light-purple;
        border-radius: $universalSize-8;
        color: $purple-color;
        font-weight: 500;
      }
    }

    .regularLink {
      font-size: $universalSize-18;
      font-weight: 400;
      line-height: $universalSize-24;
    }
    .sections {
      height: $universalSize-469;
      background: $white-color;
      padding: $universalSize-24 $universalSize-20$universalSize-24 $universalSize-60;
    }
    .shouldShowCategoryDetails {
      &.categoryDetails {
        position: absolute;
        top: $universalSize-82;
        left: 0;
        height: $universalSize-535;
        box-sizing: border-box;
        background-color: $white-color;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: $universalSize-48 $universalSize-24 0 7vw;
        border-right: $universalSize-1 solid $white-color-border;
        z-index: 9;
      }
    }

    .categoryDetails {
      .categoryDescription {
        margin: $universalSize-8 0;
        max-width: 13vw;
        font-size: $universalSize-14;
        font-weight: 300;
        line-height: $universalSize-20;
        letter-spacing: 0;
        text-align: left;
        color: $dark-purple-color;
      }
      .catIcon {
        svg {
          width: $universalSize-48;
          height: $universalSize-48;
        }
        img {
          width: $universalSize-48;
          height: $universalSize-48;
        }
      }

      .innerCategoryTitle {
        font-size: $universalSize-26;
        font-weight: 600;
        line-height: $universalSize-36;
        letter-spacing: 0;
        text-align: left;
        color: $dark-purple-color;
        margin: $universalSize-22 0 0;
      }
    }

    .imageWrapper {
      padding-top: $universalSize-40;
      display: block;
      position: absolute;
      right: $universalSize-40;
      top: $universalSize-81;
      height: $universalSize-535;
      box-sizing: border-box;
      width: 30vw;
      max-width: $universalSize-560;
      padding-left: $universalSize-40;
      border-left: $universalSize-1 solid $white-color-border;
      padding-right: 5vw;
      cursor: default;
    }
    .none {
      display: none;
    }
    // check
    .toggleIcon {
      width: $universalSize-8;
      height: $universalSize-24;
      position: relative;
      &::before,
      &::after {
        content: '';
        width: 0;
        height: 0;
        background: transparent;
        transition: none;
        // transition: transform 600ms;
        transition-timing-function: ease-in-out;
        color: $black-color;

        &.darkDesktop {
          color: $white-color;
        }
      }

      &.isActive {
        &::after {
          transform: translate($universalSize-10, $universalSize-30) rotate(-180deg);
        }
      }
      &::after {
        content: '⌄';
        position: absolute;
        top: 0;
      }
    }

    .categoryTitle {
      color: $black-color;
      &.darkDesktop {
        color: $white-color;
      }
      &.dark {
        color: $white-color;
      }
    }

    .ghumaDe {
      transform: rotate(-180deg);
      transition: transform 300ms;
      transition-timing-function: ease-in-out;
    }
    .categorySubWrapper {
      border-bottom: none;
      &.currentlyActive {
        position: relative;
        &::after {
          position: absolute;
          content: ' ';
          width: 100%;
          top: calc(100% + $universalSize-2);
          height: $universalSize-3;
          border-radius: $universalSize-3;
          background: $purple-color-3;
          width: calc(100% - $universalSize-30);
        }
        &.darkDesktop {
          &::after {
            background: $white-color;
          }
        }
      }
    }

    .mobileOnly {
      display: none;
    }
    .StyledImageContainer {
      height: $universalSize-36;
      width: $universalSize-36;
      cursor: pointer;
    }
    .buttonWrapper {
      width: $universalSize-93;
      height: $universalSize-40;
    }
  }
}
.modalWrapper {
  margin-left: auto;
  cursor: pointer;
  height: $universalSize-32;
  width: $universalSize-175;
  color: $black-color;
  align-items: center;
  gap: $universalSize-10;
  z-index: 2;
  display: flex;
  flex-direction: row;
  padding-bottom: $universalSize-10;
  margin-top: $universalSize-12;

  @media only screen and (max-width: $universalSize-1024) {
    display: none;
  }

  &.darkDesktop {
    color: $white-color;
  }
  &.dark {
    color: $white-color;
  }

  &.active::after {
    position: absolute;
    content: ' ';
    width: $universalSize-155;
    height: $universalSize-3;
    border-radius: $universalSize-3;
    top: $universalSize-78;
    background: $purple-color-3;
  }

  &.active.purple-desktop::after {
    background: $white-color;
  }
}

.relativeHeader {
  position: relative;
  height: 0;
  display: none;
  &.visible {
    display: block;
  }
  @media only screen and (min-width: $universalSize-1024) {
    height: $universalSize-80;
  }
}

.separator {
  border: $universalSize-1 dashed $white-color-border;
  margin: $universalSize-8 0;
}
