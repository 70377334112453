@import '../../styles/main.scss';

.dynamicInterlinking {
  width: 70%;
  margin: $universalSize-100 auto;

  .dynamicInterlinkingHeading {
    text-align: center;
    @include desktopHeading2();
  }

  .sectionStyle {
    margin: $universalSize-64 auto;

    h3,
    h4,
    h5,
    h6 {
      text-align: center;
      @include desktopHeading3();
    }

    .linkSection {
      display: flex;
      flex-wrap: wrap;
      gap: $universalSize-15;
      align-items: center;
      margin-top: $universalSize-40;
      .urlTag {
        padding: $universalSize-16;
        border: $universalSize-1 solid #e8e8e8;
        border-radius: $universalSize-32;
        display: flex;
        align-items: center;
        gap: $universalSize-10;
         // Flex properties for equal width
         flex: 0 1 24%;
         min-width: $universalSize-198;
         min-height: $universalSize-68;

        a {
          @include desktopPara3();
        }

        .imge {
          cursor: pointer;
        }
      }
    }
  }
}

@include respond(medium) {
  .dynamicInterlinking {
    width: 100%;
    padding: 0$universalSize-24;
    margin: $universalSize-100 0$universalSize-200 0;

    .dynamicInterlinkingHeading {
      @include mobileHeading2();
      text-align: left;
    }

    @include respond(medium) {
      .sectionStyle {
        width: 100%;
        gap: $universalSize-16;
        h3,
        h4,
        h5,
        h6 {
          @include mobileHeading3();
          text-align: left;
        }

        .linkSection {
          margin: $universalSize-32 0;
          .urlTag {
            width: 100%;
            flex-grow: 1;
            justify-content: space-between;
            a {
              @include mobilePara3();
            }
          }
        }
      }
    }
  }
}
