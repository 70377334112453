@import '../../../styles/main.scss';

.showDesktop {
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
}

.showMweb {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.loaderStyles {
  background: white;
  height: 81px;
  width: 100vw;
  color: black;

  @media (max-width: 768px) {
    height: 64px;
  }
}

.semNavbar {
  position: absolute;
  top: $universalSize-24;
  z-index: 2;
  left: $universalSize-160;
  background: transparent;

  @include respond(large) {
    left: $universalSize-24;
  }
}