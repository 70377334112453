@import '../../../styles/main.scss';

.fastagModalWrapper {
  background-color: $white-color;
  color: $blue-color-2;
  width: $universalSize-560;
  border-radius: $universalSize-16;
  border: 1px solid $white-color-3;
  height: auto;
  max-height: 97vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.fastagModalContainer {
  padding: $universalSize-24 $universalSize-30 0;
  @include respond(medium) {
    padding: $universalSize-24 $universalSize-20 0;
  }
}

.fastagModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: $purple-color-700;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-para-heading;
    letter-spacing: $universalSize-2;
  }

  span {
    cursor: pointer;
    display: flex;
  }
}

.fastagVehicleDetailsWrapper {
  border-radius: $universalSize-12;
  border: 1px solid $white-color-3;
  background: $white-color-5;
  display: flex;
  justify-content: space-between;
  margin: $universalSize-24 0 0;
  height: $universalSize-90;
}

.fastagVehicleDetailsFlex {
  margin-left: $universalSize-20;
  align-self: center;
  @include respond(medium) {
    margin-left: $universalSize-10;
  }

}

.vehicleModal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  text-align: center;
  color: $black-color;
  box-shadow: 0px 4px 6px -2px $shadow-color;
  background-color: $white-color;
  padding: $universalSize-12;
  max-height: 50%;
  border-radius: $universalSize-8;

  p {
    font-weight: 600;
    text-align: left;
  }
  span {
    display: flex;
    align-items: center;
    margin-top: $universalSize-10;
  }
}

.carImg {
  object-fit: contain;
  display: flex;
  align-self: end;
}

.fastagInputSearchText {
  text-align: start;
  color: $purple-color-700 !important;
  font-size: $font-size-small !important;
  margin-top: $universalSize-12;
}

.fastagInputContainer {
  margin-top: $universalSize-16;
  position: relative;

  input {
    width: 100%;
    padding: $universalSize-12 $universalSize-56 $universalSize-12 $universalSize-56;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $universalSize-10;
    align-self: stretch;
    border-radius: $universalSize-8;
    background: $heavy-light-purple;
    outline: none;
    border: none;
    font-size: $font-size-small;
    border: 1px solid $heavy-light-purple;
  }

  .inputOnFocus{
    background: none;
    color: $text-color;
    border: 1px solid $text-color;
    border-radius: 8px;
  }
}

.inputArrow{
  transform: rotate(90deg);
  cursor: pointer;
}

.fastagInputSearchImg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin-left: $universalSize-16;
  display: flex;
  align-items: center;
  font-size: $font-size-xmedium;
}

.fastagInputCross{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 92%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fastagList {
  margin: $universalSize-24 0 0;
  text-align: start;

  span {
    color: $text-color-3;
    font-size: $font-size-xsmall;
  }
}

.fastagListWrapper {
  margin: $universalSize-16 0;
  display: flex;
  flex-wrap: wrap;
  height: 20vh;
  max-height: 20vh;
  overflow-y: scroll;

    // For WebKit browsers
  &::-webkit-scrollbar {
    width: 5px; 
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scroll-bar-color; 
    border-radius: 10px; 
  }
}

.bankDiv {
  border: 1px solid $black-color;
  display: flex;
  padding: $universalSize-12;
  align-items: center;
  max-height: 35%;
  border-radius: $universalSize-16;
  border: 1px solid $white-color-3;
  margin: 0 $universalSize-8 $universalSize-8 0;
  cursor: pointer;

  span{
    background-color: $heavy-light-purple;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $universalSize-16;
    padding: $universalSize-1 $universalSize-2;
    border-radius: $universalSize-8;
  }
}

.selected {
  border-radius: $universalSize-16;
  border: 1px solid $button-background-color;
  background: $green-color-light;
}

.continueBtnWrapper {
  border-top: 1px solid $light-purple-color;
  margin: $universalSize-12 0;
  box-shadow: 0px -10px 6px -2px $shadow-color;
  padding-top: $universalSize-8;
  padding: $universalSize-8 $universalSize-30 0;
  @include respond(medium) {
    padding: $universalSize-24 $universalSize-20 0;
  }
  button{
    font-family: inherit;
  }
}

.findFastagProvider {
  border-top: 1px solid $light-purple-color;
  margin-bottom: $universalSize-16;
  padding-top: $universalSize-16;
  cursor: pointer;
}

.fastagProviderHeading {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  color: $light-blue-color;
  font-size: $font-size-xsmall;
  font-weight: $font-weight-para-heading;
  line-height: $universalSize-22;
}

.showProviderImg {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $universalSize-20;
}

.fastagProviderImg {
  width: 80%;
  height: auto;
  object-fit: contain;
}
