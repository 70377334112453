@import '../../../styles/main.scss';

.mobileNumberInput {
  @include flex($flex-flow: column nowrap);
  height: 100%;
  position: relative;

  label {
    margin-bottom: $universalSize-8;
    font-weight: $font-weight-card-heading;
  }

  .inputWrapper {
    position: relative;
    height: 100%;
    input {
      height: 100%;
      width: 100%;
      padding: $universalSize-14 $universalSize-64 $universalSize-14 $universalSize-48;
      border: $universalSize-1 solid #ccc;
      border-radius: $universalSize-8;
      font-size: $universalSize-16;
      line-height: $universalSize-20;
      font-weight: $font-weight-para-heading;
      &:focus {
        border-color: $purple-variant-2;
        outline: none;
      }
      @include removeDefaultStylesInput();
      @include respond(medium) {
        width: 100%;
      }
    }
  }

  .countryCode {
    color: $text-color;
    font-size: $universalSize-16;
    font-style: normal;
    font-weight: $font-weight-para-heading;
    line-height: $universalSize-20;
    position: absolute;
    left: $universalSize-16;
    top: 50%;
    transform: translateY(-50%);
  }

  &.error {
    .inputWrapper {
      input {
        border-color: $red-color-300;
      }
    }

    .errorMessage {
      color: $red-color-300;
      margin-top: $universalSize-4;
      font-size: $universalSize-12;
      line-height: $universalSize-16;
      position: absolute;
      bottom: -$universalSize-20;
      left: $universalSize-4;
    }
  }
}
